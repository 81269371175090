import styled from "@emotion/styled";
import Typography from "@/components/Typography";
import Grid from "@/components/Grid";

export const TermsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.light};
`;

export const TermsContainer = styled.div`
  ${({ theme }) => theme.container};
  padding-top: 10rem;

  header p,
  header p > a {
    // TODO: move it to the theme
    color: rgba(134, 154, 184, 1);
  }

  @media print {
    > div {
      grid-template-columns: auto;
      grid-template-rows: auto;
    }
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    padding-bottom: 6rem;
    padding-top: 5rem;

    > div {
      grid-row-gap: 2rem;
      grid-template-columns: auto;
      grid-template-rows: auto auto;
    }
  }
`;

export const TermsHeader = styled.header`
  border-bottom: solid 1px #e8e8ea;
`;

export const TermsHeaderTitle = styled(Grid)`
  padding: 2rem 0;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
`;

export const TemsButtons = styled.div`
  display: inline-flex;
  height: 76px;
  flex-wrap: wrap;
`;

export const TermsContent = styled(Typography)`
  padding: 2rem 0 4rem;
`;

export const TermsAside = styled.aside`
  padding: 52px;
  background: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  grid-row-gap: 1.6rem;
  margin-top: 13.8rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    margin-top: 0;
  }

  @media print {
    display: none;
  }
`;
