import React from "react";
import { Helmet } from "react-helmet";
import Terms from "@/views/Terms";

function TermsPage() {
  return (
    <>
      <Helmet title="Início | LEAN" />
      <Terms />
    </>
  );
}

export default TermsPage;
