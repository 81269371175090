import React, { useRef } from "react";
import { useTheme } from "@emotion/react";

import { TermsWrapper, TermsContainer, TermsHeader, TermsHeaderTitle, TemsButtons, TermsAside, TermsContent } from "./styled";
import useSectionOBserver from "@/hooks/useSectionObserver";
import { setHeaderColors } from "@/theme/base/utils";
import Grid from "@/components/Grid";
import Typography from "@/components/Typography";
import Button from "@/components/Button/Button";
import Printer from '@/assets/svg/printer.svg';
import WhatsApp from "@/assets/svg/whatsup.svg"
import { Link } from "gatsby";
import { isBrowser } from "../../utils";

function Terms() {
  const pageRef = useRef(null);
  const currentTheme = useTheme();

  useSectionOBserver(pageRef, setHeaderColors(currentTheme, "dark"));

  return (
    <TermsWrapper ref={pageRef}>
      <TermsContainer>
        <Grid rows="auto" columns="auto 30%" gap="8rem">
          <article>
            <TermsHeader>
              <Typography paragraph variant="small" color="stone">Página Inicial  > Termos de Uso</Typography>

              <TermsHeaderTitle rows="auto" columns="auto 30%" gap="1rem">
                <div>
                  <Typography variant="h2" as="h2">Termos de Uso</Typography>
                  <Typography weight={300} paragraph color="stone">Atualizado em 20/02/2022</Typography>
                </div>

                <TemsButtons>
                  <Button onClick={isBrowser() ? window.print : undefined} as="button"><Printer /> Imprimir</Button>
                  <Button variant="secondary">Baixar</Button>
                </TemsButtons>
              </TermsHeaderTitle>
            </TermsHeader>

            <TermsContent as="div">
              O presente contrato estabelece os termos e condições aos clientes ("USUÁRIO") da prestação de serviço de processamento de dados para geração de relatórios gerenciais e fiscais, conforme oferta contratada, para investidores de renda variável pela TRADERTAX, produto desenvolvido pela LEAN SERVICOS DE CONTABILIDADE LTDA (“CONTRATADA”), sociedade empresária, inscrita no CNPJ/MF sob o nº 34.479.161/0001-21, com sede à Av. República do Líbano, 251, RioMar Trade Center - Torre A - Sala 803, Pina - CEP: 51110-160, Recife – PE.
              <br />
              <br />
              As partes, USUÁRIO e CONTRATADA, estabelecem, a seguir, as condições que regerão a sua relação:
              <br />
              <br />
              1. OBJETO
              <br />
              1.1. Por meio de acesso ao site (https://www.tradertax.com.br/) e nas condições do presente termo, a CONTRATADA compromete-se, mediante prévio pagamento, à prestação de serviço de processamento de dados para geração de relatórios gerenciais e fiscais, conforme descrito na oferta contratada, contudo sempre com foco ao auxílio do USUÁRIO nas suas apurações e resultados para o pagamento do Imposto de Renda mensal, com base na legislação tributária brasileira.
              <br />
              <br />
              2. DA FORMA DE EXECUÇÃO DOS SERVIÇOS CONTRATADOS
              <br />
              2.1. Os serviços serão prestados na forma descrita na oferta anunciada à época da contratação, cujos planos e condições estarão presentes no site da CONTRATADA (https://www.tradertax.com.br/).
              <br />
              <br />
              2.2. Para prestação do serviço, faz-se necessário que o USUÁRIO realize seu cadastro junto ao site da CONTRATADA.
              <br />
              <br />
              2.3. As informações e dados coletados do USUÁRIO ficarão disponibilizados a este no sistema acima indicado.
              <br />
              <br />
              2.4. A prestação do serviço será exclusiva para uma única pessoa, jurídica ou física, por cada plano contratado, não podendo ser englobado nos serviços as declarações de outra pessoa se não aquela do contratante.
              <br />
              <br />
              3. DA REMUNERAÇÃO DA CONTRATADA
              <br />
              3.1. Os serviços ofertados pela CONTRATADA funcionam no modelo pré-pago, sendo prestados somente após a confirmação do pagamento.
              <br />
              <br />
              3.2. A remuneração da CONTRATADA dar-se-á na forma, condições e valores estipulados na oferta do plano.
              <br />
              <br />
              3.3. A qualquer momento, o USUÁRIO poderá optar por outro plano de serviços, motivo pelo qual os valores cobrados serão alterados de acordo com a nova opção escolhida.
              <br />
              <br />
              4. DAS OBRIGAÇÕES DO USUÁRIO
              <br />
              Constituem obrigações do USUÁRIO, dentre outras previstas neste termo:
              <br />
              <br />
              a) Fornecer à CONTRATADA, quando e no prazo solicitado, toda a documentação necessária para a prestação dos serviços contratados;
              <br />
              b) Fornecer todas as informações solicitadas com a veracidade e a correção necessárias para o funcionamento e operacionalização do serviço;
              <br />
              c) Pagar, previamente à prestação do serviço, o plano escolhido e contratado;
              <br />
              d) Cumprir as disposições contidas neste contrato, sob pena da CONTRATADA, a seu exclusivo critério, ao identificar qualquer violação às mesmas, optar por rescindir o contrato, interromper os serviços temporariamente e/ou notificar o USUÁRIO para que regularize a situação que gerou a adoção de quaisquer das medidas mencionadas;
              <br />
              e) Não fornecer a terceiros sua senha de acesso à área do usuário, respondendo pela guarda, devendo evitar sua perda ou utilização indevida, sendo legalmente responsável pelos danos que venham a ser causados em decorrência;
              <br />
              f) Não utilizar a plataforma de forma que possa praticar qualquer ato ilícito, infração violação de direitos ou danos à CONTRATADA ou terceiros;
              <br />
              g) Não publicar, enviar ou transmitir qualquer arquivo que contenha vírus ou qualquer outro programa que possa contaminar, destruir ou interferir no bom funcionamento dos serviços ofertados;
              <br />
              <br />
              5. DAS OBRIGAÇÕES DA CONTRATADA
              <br />
              Constituem obrigações da CONTRATADA, dentre outras previstas neste contrato:
              <br />
              <br />
              a) Executar fielmente a prestação de serviço de acordo com o plano contratado;
              <br />
              b) Prestar ao USUÁRIO quaisquer informações e esclarecimentos que se fizerem necessários para o acompanhamento da evolução dos serviços ora contratados;
              <br />
              c) Revisar ou corrigir, de forma pronta e imediata, sem qualquer ônus para o USUÁRIO, todas as falhas, deficiências, imperfeições ou defeitos apresentados na prestação do serviço durante a vigência do contrato
              <br />
              d) Disponibilizar acesso aos serviços de suporte compreendido de segunda a sexta-feira, das 08h30 às 17h30 (horário de Brasília), sem intervalo, via canais de comunicações especificados neste termo;
              <br />
              e) Manter as os dados pessoais do USUÁRIO, bem como seus registros de acesso, em sigilo, sendo que as referidas informações serão armazenadas em ambiente seguro, sendo respeitadas a intimidade, a vida privada, a honra e a imagem do USUÁRIO, nos termos e forma da legislação em vigor.
              <br />
              <br />
              6. DA LIMITAÇÃO E EXCLUSÃO DE GARANTIAS E RESPONSABILIDADES
              <br />
              6.1. A CONTRATADA garante a disponibilidade e prestação do serviço, à exceção das seguintes hipóteses:
              <br />
              <br />
              a) Ausência de fornecimento ou fornecimento incompleto/ incorreto das informações necessárias para a prestação do serviço de acordo com cada plano ofertado/ contratado;
              <br />
              b) A ausência de pagamento do plano contratado;
              <br />
              c) Caso fortuito e força maior;
              <br />
              d) Ações de terceiros que impeçam a prestação do serviço;
              <br />
              e) Interrupção ou suspensão pelas concessionárias dos serviços de telefonia/ internet;
              <br />
              f) Manutenções técnicas e/ou operacionais que exijam o desligamento temporário do sistema da CONTRATADA ou impossibilite o seu acesso, bem como quaisquer outros fatores que independam do controle da CONTRATADA;
              <br />
              g) Suspensão da disponibilização do serviço pela própria CONTRATADA.
              <br />
              <br />
              6.2. A CONTRATADA utilizará do seu melhor esforço e capacidade para que as declarações à Receita Federal ou qualquer outro órgão que o eventualmente substitua sejam realizadas, de acordo com o plano contratado, da maneira mais precisa possível e em acordo com interpretação mais habitual da legislação tributária em vigor.
              <br />
              <br />

              6.3. A CONTRATADA não se responsabiliza pelos danos gerados por uso em desconformidade com o aqui estabelecido, em quaisquer circunstâncias, ou pelos dados informados diretamente pelo USUÁRIO à Receita Federal do Brasil.
              <br />
              <br />

              6.4. A CONTRATADA não será responsável pelo conteúdo (informações, senhas, cópias de informações, etc.) fornecido pelo USUÁRIO, não sendo, portanto, estas informações editadas em momento algum. A responsabilidade pelas informações fornecidas à CONTRATADA é sempre do USUÁRIO;
              <br />
              <br />

              6.5. A parceria mantém a individualidade de cada uma das partes, não havendo para efeito de legislação trabalhista qualquer vínculo de formação de grupo, cada qual respondendo por seus próprios funcionários e prestadores de serviços e quaisquer encargos que por ventura vierem a incidir.
              <br />
              <br />

              6.6. As eventuais obrigações assumidas individualmente por cada um dos participantes, não gera a outra parte qualquer responsabilidade pelas obrigações assumidas individualmente perante terceiros, não havendo por este instrumento formação de sociedade de qualquer tipo.
              <br />
              <br />

              7. DOS DADOS
              <br />
              7.1. Ao cadastrar-se no site da CONTRATADA (https://www.tradertax.com.br/), contratando ou não os serviços ofertados, o USUÁRIO estará vinculado ao presente termos de uso, concordando com suas disposições, principalmente com relação ao consentimento para o acesso, coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração aos dados do licenciado pela licenciante, necessárias para a integral execução dos serviços ofertados. Em caso de discordância com os termos aqui apresentados, a o cancelamento do cadastro deve ser solicitado.
              <br />
              <br />

              7.2. A CONTRATADA fará a coleta, conservação e tratamento de dados do USUÁRIO por imposição do cumprimento de obrigações legais, para cumprimento do presente contrato e ainda porque o USUÁRIO dá o seu consentimento, nos termos do TERMO DE CONSENTIMENTO.
              <br />
              <br />

              7.3. A CONTRATADA será a responsável pelo tratamento dos dados do USUÁRIO, por ele fornecido ou por seu assessor nos termos da cláusula 8, cujo processamento de dados será interno, ficando desde já CONTRATADA expressamente autorizada a, para melhor aproveitamento e eficiência da prestação do serviço contratado, efetuar esse processamento externamente.
              <br />
              <br />

              7.4. A coleta de dados será feita exclusivamente pelos canais de comunicação indicados pela CONTRATADA.
              <br />
              <br />

              7.5. A CONTRATADA fará o tratamento de dados com a finalidade exclusiva de prestação do serviço contratado e na forma contratada, não podendo, em hipótese alguma, salvo autorização expressa, utilizar os dados coletados para outra finalidade.
              <br />
              <br />

              7.6. A CONTRATADA vai comunicar ou transferir em parte ou na sua totalidade os dados pessoais do USUÁRIO a entidades públicas e/ou privadas sempre que tal decorra de obrigação legal e/ou seja necessário para cumprimento deste ou outros contratos firmado entre as partes, com destaque para serviços de “nuvens” e armazenamento de dados, ficando para tal expressamente autorizada pelo USUÁRIO.
              <br />
              <br />

              7.7. As informações financeiras e pessoais serão tratadas da maneira mais precisa possível e em acordo com interpretação mais habitual da legislação tributária em vigor, consistindo no fornecimento dos dados aos órgãos competentes.
              <br />
              <br />

              7.8. Após a contratação, do serviço ofertado, A CONTRATADA conservará os dados do USUÁRIO pelo prazo de 365 DIAS, sendo o referido prazo renovado a cada nova contratação.
              <br />
              <br />

              7.9. Decorrido o prazo acima descrito sem que tenha ocorrido nenhuma nova contratação, todos os dados do USUÁRIO, incluindo os dados pessoais, dados de conta, dados financeiros e fiscais, em poder da CONTRATADA serão excluídos permanentemente do banco de dados da CONTRATADA, independentemente de terem sido extraídas ou não pelo USUÁRIO.
              <br />
              <br />

              7.10. Além do acima exposto, os dados referentes à data e hora de acesso e ao endereço de protocolo de internet utilizado pelo USUÁRIO para acessar o Site e o sistema permanecerão armazenadas pela CONTRATADA pelo prazo legal a contar da data de cada acesso realizado, independentemente do término da relação jurídica e comercial entre as partes, podendo ser armazenados por um período maior de tempo mediante ordem judicial.
              <br />
              <br />

              7.11. Durante o prazo acima mencionado, os dados do USUÁRIO poderão ser solicitados à CONTRATADA.
              <br />
              <br />

              7.12. O USUÁRIO poderá solicitar à CONTRATADA e esta salvo impedimento legal vai salvaguardar os direitos deste de acesso aos dados pessoais que lhe digam respeito, bem como a sua retificação ou o seu apagamento, e a limitação do tratamento, e o direito de se opor ao tratamento, bem como do direito à portabilidade dos dados, o direito de retirar o seu consentimento em qualquer altura, sem comprometer a licitude do tratamento efetuado com base no cumprimento de obrigações legais ou com base no consentimento previamente dado.
              <br />
              <br />

              7.13. O USUÁRIO ainda terá direito à confirmação da existência de tratamento, anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a legislação em vigor, informação das entidades públicas e privadas com as quais a CONTRATADA realizou uso compartilhado de dados, além de direito à cópia eletrônica integral de seus dados pessoais.
              <br />
              <br />

              7.14. O não fornecimento dos dados pessoais, financeiros e fiscais requisitados pela CONTRATADA para a prestação do serviço, com destaque para os dados das operações de renda variável tornará a prestação do serviço impossível, devendo-se, na ausência do fornecimento, ser aplicada a cláusula 12 do presente termo.
              <br />
              <br />

              7.15. Os direitos do USUÁRIO acima elencados poderão ser exercidos a qualquer momento, mediante solicitação à CONTRATADA, exclusivamente através do e-mail: contato@tradertax.com.br
              <br />
              <br />

              7.16. Em caso de impossibilidade de adoção imediata dos requerimentos do USUÁRIO na forma da cláusula 7.15, a CONTRATADA enviará ao USUÁRIO resposta em que indicará as razões de fato ou de direito que impedem a adoção imediata da providência.
              <br />
              <br />

              7.17. Em caso de violação de dados pessoais a CONTRATADA notificará esse facto ao órgão competente nos termos e condições previstos na legislação. Se essa violação for suscetível de implicar um elevado risco para os direitos e liberdades do USUÁRIO comunica-lhe esse fato.
              <br />
              <br />

              8. DO ACESSO DE TERCEIROS
              <br />
              8.1. A CONTRATADA poderá, com o intuito de facilitar a comunicação e o tráfego de informações entre o USUÁRIO e seu assessor/consultor financeiro, diminuir entraves burocráticos e margens de erro na entrega das dos dados necessários para a prestação do serviço, mediante autorização eletrônica do USUÁRIO, fornecer a terceiros, com destaque para assessores/consultores financeiros, indicados pelo USUÁRIO, acesso ao perfil e dados deste, mediante perfil e senha próprios.
              <br />
              <br />

              8.2. A CONTRATADA não será responsável pelo conteúdo das informações e dados fornecidos pelo terceiro, referente às declarações do USUÁRIO.
              <br />
              <br />

              8.3. O USUÁRIO que terá acesso ao perfil de terceiros, desde já, declara que é o único responsável pelas informações e dados fornecidos à CONTRATADA e que não está proibido por determinação legal e/ou contratual de passar INFORMAÇÕES, INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS, bem como quaisquer outros dados à CONTRATADA, necessários para a execução do serviço oferecido por este termo.
              <br />
              <br />

              9. DA PROPRIEDADE INTELECTUAL
              <br />
              O USUÁRIO com o mero cadastro e/ou com a contratação efetiva dos serviços não adquire nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como todo o conteúdo disponibilizado no site e nas comunicações entre as partes, incluindo, mas não se limitando a textos, gráficos, imagens, logotipos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material.
              <br />
              <br />

              9.1. Em função do acima exposto, a CONTRATADA será a única responsável por infrações a direito de propriedade intelectual de terceiros, inclusive aquelas relacionadas a materiais, equipamentos, programas de computador ou processos de execução protegidos pela legislação em vigor, que tenham sido utilizados na execução dos serviços ora contratados, respondendo diretamente por quaisquer reclamações, indenizações, taxas ou comissões que forem devidas.
              <br />
              <br />

              10. DA COMUNICAÇÃO ENTRE AS PARTES
              <br />
              A comunicação entre as partes será exclusivamente através do e-mail contato@tradertax.com.br ou pelos canais de atendimento indicados no site da contratada (https://www.tradertax.com.br/).
              <br />
              <br />

              11. DA VIGÊNCIA DO CONTRATO
              <br />
              O presente termo vigorará pelo prazo do serviço contratado, sendo renovado e aplicado automaticamente aos novos contratos firmados, ainda que sejam firmados de forma esporádica.
              <br />
              <br />

              12. DA RESCISÃO E ENCERRAMENTO
              <br />
              O presente termo poderá ser rescindido ou encerrado, sem custo para qualquer das partes, após a realização do pagamento do plano e antes da efetiva prestação do serviço. 12.1. Na hipótese acima mencionada, o valor pago será devolvido ao cliente na conta corrente por ele indicado no prazo de até 20 dias úteis. 12.2. Após o pagamento, a prestação de serviço não seja realizada ou seja realizada de forma inadequada em razão das ausência ou inconsistência de informações e documentos solicitados, o valor desembolsado pelo USUÁRIO não será devolvido.
              <br />
              <br />

              13. DAS DISPOSIÇÕES GERAIS
              <br />
              13.1. O USUÁRIO declara ter conhecimento de que, na forma da legislação vigente, a obrigação do recolhimento do imposto de renda mensal, assim como da declaração anual é de sua responsabilidade e que o imposto de renda deve ser recolhido até o último dia do mês subsequente ao que se realizou o ganho, estando ciente, caso atrase esse pagamento, que recolherá esse imposto com a incidência das multas e juros, os quais não são devidos à CONTRATADA.
            </TermsContent>
          </article>

          <div>
            <TermsAside>
              <header>
                <Typography color="light" variant="h5" as="h5">Ficou com dúvida</Typography>
              </header>

              <Typography color="light">
                Se não sabe como proceder mesmo com as informações ao lado, fique à vontade para entrar em contato conosco
              </Typography>

              <div>
                <Typography as="h6" color="light" weight={700}>Atendimento Onine</Typography>
                <Typography color="light" as={Link} to="#">Iniciar Atendimento</Typography>
              </div>

              <div>
                <Typography as="h6" color="light" weight={700}>E-mail</Typography>
                <Typography color="light">contato@lean.com.br</Typography>
              </div>

              <div>
                <Button variant="light">
                  <WhatsApp /> Entrar em contato
                </Button>
              </div>
            </TermsAside>
          </div>
        </Grid>
      </TermsContainer>
    </TermsWrapper>
  );
}

export default Terms;
